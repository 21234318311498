import React from "react";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { API_BASE_URL } from "../config";
import FillformPopup from "./fillFormpopup";

const Body = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleApplyNowClick = () => {
    setShowPopup(true);
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const [description, setDescription] = useState("");
  const [heading, setHeading] = useState("");
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const getHomeData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getData`);
      setDescription(response.data.data?.Description);
      setHeading(response.data.data?.Heading);
      setSelectedImage(response.data.data?.Photos);
      setToggleSwitch(response?.data?.data?.Published);
    } catch (e) {
      console.log("err", e);
    }
  };
  useEffect(() => {
    getHomeData();
  }, []);
  return (
    <div onClick={() => handleApplyNowClick()}
      className="lg:w-full lg:h-auto flex justify-center items-center w-auto h-[230px]"
      style={{
        backgroundImage: toggleSwitch
          ? `url(${selectedImage})`
          : 'url("./images.jpg")',
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="flex flex-col text-center lg:mt-[21rem] lg:ml-0 lg:mx-10 2xl:mx-[17rem]">
        <div
          style={{
            borderRadius: "17.878px",
             padding:"20px"
        
          }}
          className="px-4 py-2 mx-4 lg:mx-0 rounded-2xl lg:px-0 lg:py-0"
        >
        <div>
          <p style={{
            background: "rgba(26, 35, 56, 0.70)"
          }}
          className=" text-center text-[#ffcc01] font-['Roboto'] lg:text-4xl lg:mt-[0.5rem] text-sm font-bold lg:ml-[1rem] ">
            {toggleSwitch ? heading : "St. Michael’s School"}
          </p>
          </div>
          <div className="lg:mb-[1rem] lg:ml-[1rem]">
            {toggleSwitch ? (
              <p
                style={{ fontFamily: "Lato, sans-serif", background: "rgba(26, 35, 56, 0.70)" }}
                className="lg:w-[36rem] capitalize break-words lg:text-3xl font-bold text-base text-[#FFFFFF] font-['Roboto'] lg:leading-[55px]"
              >
                {description}
              </p>
            ) : (
              <p
                style={{ fontFamily: "Lato, sans-serif", background: "rgba(26, 35, 56, 0.70)" }}
                className="lg:text-3xl font-bold text-base text-[#FFFFFF] font-['Roboto'] lg:leading-[55px]"
              >
                Equipping
                <span className="lg:text-3xl font-bold text-base text-[#FFA901] font-['Roboto'] lg:leading-[55px]">
                  Young Leaders
                </span>
                With <br />
                Core Values and Future Ready Skills.
              </p>
            )}
          </div>
        </div>
   <div className="lg:mb-[8rem] mt-[5rem]">
          <button
          
            className="hidden whitespace-nowrap lg:gap-2.5 text-center font-['Roboto'] font-bold lg:leading-4 text-base bg-[#fff] py-4 px-4 lg:py-[18px] lg:px-[20px]  rounded-lg text-[#1A2338]"
          >
            Apply Now
          </button>

          <FillformPopup setShowPopup={setShowPopup} showPopup={showPopup} />
          {/* <a href="/Admissions"> */}
          {/* <button
            onClick={() => handleApplyNowClick()}
            className="lg:gap-2.5 text-center font-['Roboto'] font-bold lg:leading-4 text-base bg-[#fff] py-4 px-4 lg:py-[18px] lg:px-[20px]  rounded-lg text-[#1A2338]"
          >
            Apply Now
          </button>

          <FillformPopup setShowPopup={setShowPopup} showPopup={showPopup} /> */}
          {/* </a> */}
        </div> 
      </div>
    </div>
  );
};

export default Body;
