import * as React from "react";

const Admission = () => {
  //   const [activeStep, setActiveStep] = useState("");

  return (
    <div className="lg:my-[3rem] bg-[#fff] w-full">
      <div className="flex flex-col lg:flex-row items-center lg:items-start lg:w-screen">
        <div className="lg:ml-[5rem] my-[26px] lg:h-1/2 lg:w-1/3 w-[330px]">
          <img className="rounded-lg" src="/aboutImage3.jpg" alt="images" />
        </div>
        <div className="lg:w-1/2 mx-[2rem] mb-[2rem] lg:mb-0">
          <p className="font-medium font-['Roboto'] text-justify lg:text-2xl  text-base leading-[29px] text-[#1A2338]  mt-2 lg:mt-[2rem] ">
            Admissions for the Academic Year 2025-2026 are now open.Parents
            interested in providing their children with a balanced educational
            experience that fosters both academic and personal growth are
            invited to initiate the admissions process. There are multiple
            avenues to begin your enrollment journey.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Admission;
